<template>
    <modal-lateral ref="modalFiltroGeneral" titulo="Filtro">
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 103px)">
            <div class="row mx-0 mb-3 px-4">
                <div class="col-12 bg-light-f5 cr-pointer d-middle pr-2 border br-8 " style="height:32px;" @click="cajaShowDrop = !cajaShowDrop">
                    <span class="f-14 text-general"> Cajas </span>
                    <div class="col-auto ml-auto d-middle pr-0">
                        <div v-if="cajasFiltradas.length > 0" class="rounded-circle text-white bg-general3 d-middle-center" style="width:20px;height:20px;">
                            {{ cajasFiltradas.length }}
                        </div>
                        <i :class="`${cajaShowDrop ? 'icon-angle-up' : 'icon-angle-down'}  text-general f-18`" />
                    </div>
                </div>
                <template v-if="cajaShowDrop">
                    <div v-for="(item, i) in cajas" :key="i" class="col-12 mt-3 pl-4">
                        <el-checkbox v-model="item.checked" class="check-dark mr-2" />
                        {{ item.nombre }}
                    </div>
                </template>
            </div>
            <div class="row mx-0 mb-3 px-4">
                <div class="col-12 bg-light-f5 cr-pointer d-middle pr-2 border br-8 " style="height:32px;" @click="cuentaShowDrop = !cuentaShowDrop">
                    <span class="f-14 text-general"> Cuentas </span>
                    <div class="col-auto ml-auto d-middle pr-0">
                        <div v-if="cuentasFiltradas.length > 0" class="rounded-circle text-white bg-general3 d-middle-center" style="width:20px;height:20px;">
                            {{ cuentasFiltradas.length }}
                        </div>
                        <i :class="`${cuentaShowDrop ? 'icon-angle-up' : 'icon-angle-down'}  text-general f-18`" />
                    </div>
                </div>
                <template v-if="cuentaShowDrop">
                    <div v-for="(item, i) in cuentas" :key="i" class="col-12 mt-3 pl-4">
                        <el-checkbox v-model="item.checked" class="check-dark mr-2" />
                        {{ item.nombre }}
                    </div>
                </template>
            </div>
            <div class="row mx-0 mb-3 px-4">
                <div class="col-12 bg-light-f5 cr-pointer d-middle pr-2 border br-8 " style="height:32px;" @click="pasarelaShowDrop = !pasarelaShowDrop">
                    <span class="f-14 text-general"> Pasarelas </span>
                    <div class="col-auto ml-auto d-middle pr-0">
                        <div v-if="pasarelasFiltradas.length > 0" class="rounded-circle text-white bg-general3 d-middle-center" style="width:20px;height:20px;">
                            {{ pasarelasFiltradas.length }}
                        </div>
                        <i :class="`${pasarelaShowDrop ? 'icon-angle-up' : 'icon-angle-down'}  text-general f-18`" />
                    </div>
                </div>
                <template v-if="pasarelaShowDrop">
                    <div v-for="(item, i) in pasarelas" :key="i" class="col-12 mt-3 pl-4">
                        <el-checkbox v-model="item.checked" class="check-dark mr-2" />
                        {{ item.nombre }}
                    </div>
                </template>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col">
                <div class="bg-white cr-pointer text-general border-black d-middle-center br-8" style="height:32px;" @click="limpiar">
                    Limpiar
                </div>
            </div>
            <div class="col">
                <div class="bg-general cr-pointer text-white br-8 d-middle-center" style="height:32px;" @click="filtrar">
                    Filtrar
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import FlujoGeneral from "~/services/flujoDinero/flujo_general";
export default {
    data(){
        return {
            checked: false,
            cajaShowDrop: false,
            cuentaShowDrop: false,
            pasarelaShowDrop: false,
            cajas: [],
            cuentas: [],
            pasarelas: [],
            fecha: '',
        }
    },
    computed: {
        filtro(){
            return (this.cajasFiltradas.length > 0 || this.cuentasFiltradas.length > 0 || this.pasarelasFiltradas.length > 0) ? 1 : 0
        },
        cajasFiltradas(){
            return this.cajas.filter(el => el.checked).map(({id}) => id)
        },
        cuentasFiltradas(){
            return this.cuentas.filter(el => el.checked).map(({id}) => id)
        },
        pasarelasFiltradas(){
            return this.pasarelas.filter(el => el.checked).map(({id}) => id)
        },
    },
    methods: {
        toggle({filtro, fecha}){
            if (!filtro){
                this.fecha = fecha
                this.filtroFlujoGeneral()
            }
            this.$refs.modalFiltroGeneral.toggle();
        },
        async filtroFlujoGeneral(){
            try {

                const params = {
                    fecha: this.fecha
                }

                const { data } = await FlujoGeneral.filtroFlujoGeneral(params)
                data.data.cajas.map(el =>
                    el.checked = false
                )
                this.cajas = data.data.cajas
                data.data.cuentas.map(el =>
                    el.checked = false
                )
                this.cuentas = data.data.cuentas
                data.data.pasarelas.map(el =>
                    el.checked = false
                )
                this.pasarelas = data.data.pasarelas
            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.cajas.map(el =>
                el.checked = false
            )
            this.cuentas.map(el =>
                el.checked = false
            )
            this.pasarelas.map(el =>
                el.checked = false
            )
            this.filtrar()
        },
        filtrar(){
            const params = {
                cajas: this.cajasFiltradas,
                cuentas: this.cuentasFiltradas,
                pasarelas: this.pasarelasFiltradas,
                filtro: this.filtro
            }
            console.log({params});
            this.$emit('filtrar', params)
            this.$refs.modalFiltroGeneral.toggle();
        },
    }
}
</script>
